<template>
	<div class="change-box">
		<div class="content-title">商户信息</div>
		<template v-if="merchantInfo">
			<el-row>
				<el-col :span="6" class="item-title">商户全称</el-col>
				<el-col :span="6" class="item-value">{{
					merchantInfo.merFullName
				}}</el-col>
				<el-col :span="4" class="item-title">商户简称</el-col>
				<el-col :span="6" class="item-value">{{
					merchantInfo.merSubName
				}}</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">联系人姓名</el-col>
				<el-col :span="6" class="item-value">{{
					merchantInfo.contactName
				}}</el-col>
				<el-col :span="4" class="item-title">联系人手机号</el-col>
				<el-col :span="6" class="item-value">
					{{ hideContactFn(merchantInfo.contactPhone) }}
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="6" class="item-title">所属地区</el-col>
				<el-col :span="6" class="item-value"
					>{{ merchantInfo.provinceZh }}-{{
						merchantInfo.cityZh
					}}</el-col
				>
				<el-col :span="4" class="item-title">商户状态</el-col>
				<el-col :span="6" class="item-value">{{
					merchantInfo.status | newMerchantStatusFM
				}}</el-col>
			</el-row>
			<template v-if="merchantInfo.merchantType == '3'">
				<el-row>
					<el-col :span="6" class="item-title">营业执照名称</el-col>
					<el-col :span="6" class="item-value">{{
						merchantInfo.businessLicenseName
					}}</el-col>
					<el-col :span="4" class="item-title">法人姓名</el-col>
					<el-col :span="6" class="item-value">{{
						merchantInfo.legalPerson
					}}</el-col>
				</el-row>
				<el-row>
					<el-col :span="4" class="item-title">营业执照有效期</el-col>
					<el-col :span="6" class="item-value"
						>{{ merchantInfo.businessLicenseStartDate }}-{{
							merchantInfo.businessLicenseEndDate
						}}</el-col
					>
				</el-row>
			</template>
			<el-row v-if="!isOrg">
				<el-col :span="6" class="item-title">开通时间</el-col>
				<el-col :span="6" class="item-value">{{
					merchantInfo.createTime
				}}</el-col>
			</el-row>
			<div class="content-title">结算信息</div>
			<el-row>
				<el-col :span="6" class="item-title">结算方式</el-col>
				<el-col :span="6" class="item-value">{{
					merchantInfo.accountType | newAccountTypeFM
				}}</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">银行账号</el-col>
				<el-col :span="6" class="item-value">{{
					merchantInfo.accountNo
				}}</el-col>
				<el-col :span="4" class="item-title">结算账户名</el-col>
				<el-col :span="6" class="item-value">{{
					merchantInfo.accountName
				}}</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">开户行名称</el-col>
				<el-col :span="6" class="item-value">{{
					merchantInfo.bankName
				}}</el-col>
				<el-col :span="4" class="item-title">所属省市</el-col>
				<el-col :span="6" class="item-value"
					>{{ merchantInfo.accountProvinceZh }}-{{
						merchantInfo.accountCityZh
					}}</el-col
				>
			</el-row>
			<el-row>
				<el-col :span="6" class="item-title">银行卡预留手机号</el-col>
				<el-col :span="6" class="item-value">
					{{ hideContactFn(merchantInfo.accountMobileNo) }}
				</el-col>
				<el-col :span="4" class="item-title">结算人身份证号</el-col>
				<el-col :span="6" class="item-value">
					{{ maskIDNumber(merchantInfo.settleIdCard) }}
				</el-col>
			</el-row>
			<div class="content-title">费率信息</div>
			<table
				class="rate-table"
				style="width:950px;"
				v-if="merRateList[0]"
			>
				<tr>
					<th>业务类型</th>
					<th v-if="merRateList[0].defaultStatus != '1'">卡类型</th>
					<th v-if="merRateList[0].defaultStatus != '1'">
						T1费率（%）
					</th>
					<th>T0费率（%）</th>
					<th v-if="merRateList[0].defaultStatus != '1'">
						T0提现费（元）
					</th>
				</tr>
				<tr v-for="item in merRateList" :key="item.id">
					<td>{{ item.payTypeCode | payType }}</td>
					<td v-if="merRateList[0].defaultStatus != '1'">
						{{ item.cardType | formateCardType }}
					</td>
					<td v-if="merRateList[0].defaultStatus != '1'">
						<div>
							<span
								v-if="
									item.payTypeCode == 'POS' &&
										item.cardType == '2'
								"
								>比例：</span
							>{{ item.t1Rate }}
						</div>
						<div
							v-if="
								item.payTypeCode == 'POS' &&
									item.cardType == '2' &&
									item.t1TopFee
							"
						>
							封顶{{ item.t1TopFee }}
						</div>
					</td>
					<td>
						{{ item.t0Rate }}
					</td>
					<td v-if="merRateList[0].defaultStatus != '1'">
						<div
							v-if="payTypeCode == 'POS' && item.cardType == '1'"
						>
							{{ item.cashFee }}
						</div>
						<span v-else>-</span>
					</td>
				</tr>
			</table>

			<br />
		</template>
	</div>
</template>
<script>
import { MerchantApi } from "@/api";
import { mapState } from "vuex";
export default {
	data() {
		return {
			merchantNo: "",
			merchantInfo: {},
			businessLicense: {},
			settleAccountInfo: {},
			legalPerson: {},
			shopInfo: {},
			userRegistrationInfo: {},
			merRateList: [],
			state: "",
			nativeMerInfo: ""
		};
	},
	computed: {
		...mapState("app", ["userInfo"]),
		isOrg() {
			return this.userInfo.org;
		}
	},
	created() {
		if (this.$route.query.merchantNo) {
			this.merchantNo = this.$route.query.merchantNo;
		}
		this.getDetail();
	},
	methods: {
		async getDetail() {
			let result = await MerchantApi.getMerchantDetail(this.merchantNo);
			debugger;
			if (result.success) {
				this.merchantInfo =
					(result.data && result.data.merchantInfoDo) || {};
				this.merRateList =
					(result.data && result.data.merRateList) || [];
			}
		},
		// 身份证脱敏
		maskIDNumber(IDCode) {
			if (typeof IDCode === "string") {
				return IDCode.replace(/^(\d{6})\d+(\d{4})$/, "$1********$2");
			} else {
				return " ";
			}
		},
		// 手机号脱敏
		hideContactFn(contact) {
			if (typeof contact === "string") {
				return contact.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
			} else {
				return " ";
			}
		}
	}
};
</script>
